// NoteIndicatorOverlay.js

import React from 'react';
import Tooltip from 'rc-tooltip';
import NoteIndicator from './NoteIndicator';

class NoteIndicatorOverlay extends React.Component {
  render() {
    const {
      isSavePending,
      isTimesheetReadOnly,
      row,
      updateNarrative,
      tooltip,
      timesheet,
      entries,
      labels,
    } = this.props;

    const hasProjectSelection = !!(row.Operation || row.IndirectCode);

    const content = (
      <NoteIndicator
        isReadOnly={
          !hasProjectSelection ||
          isSavePending ||
          row.closed ||
          isTimesheetReadOnly
        }
        isSavePending={isSavePending}
        note={row.Narrative}
        onDialogClose={() => this.props.setActiveRow(null)}
        updateNarrative={updateNarrative}
        disabled={tooltip}
        timesheet={timesheet}
        entries={entries}
        labels={labels}
        isTimesheetReadOnly={isTimesheetReadOnly}
      />
    );

    if (tooltip) {
      return (
        <Tooltip overlay={<span>{tooltip}</span>} placement={'left'}>
          <span>{content}</span>
        </Tooltip>
      );
    }

    return content;
  }
}

export default NoteIndicatorOverlay;
