import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import DayTypeCellOverlay from './DayTypeCellOverlay';
import TimesheetDayTitles from './TimesheetDayTitles';

const mql = window.matchMedia(`(max-width: 1023px)`);

function DayTypeSelectionRow({
  labels,
  mode,
  onClear,
  onSelect,
  optionsBuilder,
  row,
  savePending,
  totals,
  company,
  companyConfig
}) {
  const [mobile, setMobile] = useState(mql.matches);

  const mediaQueryChanged = () => {
    setMobile(mql.matches);
  };
  useEffect(() => {
    mql.addListener(mediaQueryChanged);
    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  }, []);

  const showPercentComplete = companyConfig?.AllowTSPercent;
  const showOutlook = companyConfig?.AllowTSOutlook;

  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;
  const titleClass = classNames('title col-xs-12', {
    'col-md-1': showNeither,
    'col-md-2': showOutlookOnly || showPercentOnly || showBoth
  });
  const totalClass = classNames('total col-xs-12', {
    'col-md-2': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-3': showNeither
  });
  const daysClass = classNames('days col-xs-12', {
    'col-md-6': showBoth,
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither
  });
  return (
    <div className="timesheet-line row timesheet-days timesheet-line--daytype">
      <div className={titleClass}>
        <span>{row.Description}</span>
      </div>
      <div className={totalClass}>
        {row.TotalDays} day{row.TotalDays === 1 ? '' : 's'}
      </div>
      <div className={daysClass}>
        <TimesheetDayTitles entries={row.entries} />
        <div className="day-inputs col-md-12 col-xs-9">
          {row.entries.map((entry, i) => (
            <DayTypeCellOverlay
              approved={totals[i].Approved}
              date={entry.Date}
              invalidSelection={entry.InvalidSelection}
              isSavePending={savePending[entry.id] || savePending[entry.Date]}
              key={entry.Date}
              labels={labels}
              mode={mode}
              onChange={onSelect}
              options={optionsBuilder(i)}
              posted={totals[i].Posted}
              selection={entry.Selection}
              submitted={totals[i].Submitted}
              chargeableHours={
                row.Description === 'On the Road' ? totals[i].Chargeable : 0
              }
              nonChargeableHours={
                row.Description === 'On the Road' ? totals[i].NonChargeable : 0
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DayTypeSelectionRow;
