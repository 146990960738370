export default function getDayOfWeek(d) {
  var date = new Date(d);
  var weekday = new Array(7);
  weekday[0] = 'Sun';
  weekday[1] = 'Mon';
  weekday[2] = 'Tue';
  weekday[3] = 'Wed';
  weekday[4] = 'Thu';
  weekday[5] = 'Fri';
  weekday[6] = 'Sat';

  return weekday[date.getUTCDay()];
}
