import gql from 'graphql-tag';
import {timesheetFields} from '../shared/utils/queries';

export const TIMESHEET_DETAIL_QUERY = gql`
  query TimesheetDetailQuery($date: String!, $timesheetUser: String) {
    timesheet(date: $date, empId: $timesheetUser) {
      ${timesheetFields}
    }
  }
`;

//New query (doesnt replace the old job search)
//arguments are company and user input
export const JOB_BY_COMPANY_SEARCH = gql`
  query JobsByCompany(
    $company: String!
    $empId: String!
    $periodStartDate: String!
    $periodEndDate: String!
  ) {
    getJobsByCompany(
      company: $company
      empId: $empId
      periodStartDate: $periodStartDate
      periodEndDate: $periodEndDate
    ) {
      value
      label
      ProjectID
      ProjectDesc
    }
  }
`;

export const PROJECT_LOOKUP_QUERY = gql`
  query Projects(
    $company: String!
    $empId: String!
    $periodStartDate: String!
    $periodEndDate: String!
  ) {
    projects(
      company: $company
      empId: $empId
      periodStartDate: $periodStartDate
      periodEndDate: $periodEndDate
    ) {
      value
      label
    }
  }
`;

export const INDIRECT_LOOKUP_QUERY = gql`
  query IndirectCodes($company: String!) {
    indirectCodes(company: $company) {
      value
      label
    }
  }
`;

export const JOB_LOOKUP_QUERY = gql`
  query Jobs($projectId: String!, $company: String!) {
    jobs(projectId: $projectId, company: $company) {
      value
      label
    }
  }
`;

export const OPERATION_LOOKUP_QUERY = gql`
  query Operations($jobNum: String!, $company: String!) {
    operations(jobNum: $jobNum, company: $company) {
      value
      label
      Rework
      RunQty
      CompletedQty
      Outlook
      OpComplete
    }
  }
`;

export const REMAININGHOURS_LOOKUP_QUERY = gql`
  query RemainingHours(
    $company: String!
    $jobNum: String!
    $assemblySeq: String!
    $oprSeq: String!
  ) {
    remainingHours(
      company: $company
      jobNum: $jobNum
      assemblySeq: $assemblySeq
      oprSeq: $oprSeq
    ) {
      value
      label
    }
  }
`;

export const COMPANYFLAG_LOOKUP_QUERY = gql`
  query CompanyFlag($company: String!) {
    companyFlag(company: $company) {
      value
      label
      AllowNCR
      AllowOnCall
      AllowOvernight
      AllowTSOutlook
      AllowTSPercent
    }
  }
`;

export const REQ_APPROVED_VENDOR_LOOKUP_QUERY = gql`
  query Req_ApprovedVendors($company: String!) {
    approvedVendors(company: $company) {
      VendorNum
      VendorID
      VendorName
      UnitCost
      CurrencyCode
    }
  }
`;

export const REQ_APPROVERS_LOOKUP_QUERY = gql`
  query Req_Approvers($company: String!) {
    approverList(company: $company) {
      value
    }
  }
`;

export const REQ_JOBLIST_LOOKUP_QUERY = gql`
  query Req_JobList($company: String!) {
    jobList(company: $company) {
      ProjectID
      ProjectDesc
      JobNum
      JobDesc
      ProjectManager
    }
  }
`;

export const REQ_DEFAULT_ADDRESS_LOOKUP_QUERY = gql`
  query Req_DefAddr($company: String!) {
    defAddr(company: $company) {
      Company_Name
      Company_Address1
      Company_Address2
      Company_Address3
      Company_City
      Company_State
      Company_Zip
      Company_Country
      Company_CountryNum
    }
  }
`;

export const REQ_COUNTRY_LOOKUP_QUERY = gql`
  query Req_Country($company: String!) {
    reqCountry(company: $company) {
      Country_CountryNum
      Country_Description
    }
  }
`;

export const REQ_PART_CLASS_LOOKUP_QUERY = gql`
  query Req_PartClass($company: String!) {
    partClass(company: $company) {
      value
      label
    }
  }
`;

export const REQ_PARTLIST_LOOKUP_QUERY = gql`
  query Req_PartList($company: String!) {
    partList(company: $company) {
      PartNum
      PartDesc
      ClassID
      IUM
    }
  }
`;

export const REQ_CURRENCY_CODE_LOOKUP_QUERY = gql`
  query Req_CurrencyCode($company: String!) {
    Req_CurrencyCode(company: $company) {
      Currency_CurrencyCode
    }
  }
`;

export const REQ_ANALYSIS_CODE_LOOKUP_QUERY = gql`
  query AnalysisCodes($company: String!) {
    acAnalysisCodes(company: $company) {
      AnalysisCd_Company
      AnalysisCd_AnalysisCode
      AnalysisCd_Description
      AnalysisCd_InActive
    }
  }
`;

export const REQ_ALLOW_REQ_LOOKUP_QUERY = gql`
  query Req_AllowReq($company: String!) {
    allowReq(company: $company) {
      UserFile_DcdUserID
      UserFile_Name
    }
  }
`;

export const REQ_HEAD_LOOKUP_QUERY = gql`
  query Req_Head($company: String!, $requestorID: String!) {
    reqHead(company: $company, requestorID: $requestorID) {
      ReqHead_ReqNum
      ReqHead_RequestorID
      ReqHead_RequestDate
      ReqHead_CreatedOn
      ReqHead_OpenReq
      Calculated_Status
      Calculated_Approver
    }
  }
`;

export const REQ_DETAIL_LOOKUP_QUERY = gql`
  query Req_Detail($company: String!, $ReqNum: Int!) {
    reqDetail(company: $company, ReqNum: $ReqNum) {
      ReqDetail_ReqNum
      ReqDetail_ReqLine
      ReqDetail_OpenLine
      ReqDetail_PartNum
      ReqDetail_LineDesc
      Project_ProjectID
      Project_Description
      JobHead_JobNum
      ReqDetail_OrderQty
      ReqDetail_IUM
      ReqDetail_UnitCost
      ReqDetail_CurrencyCode
      Calculated_ExtCost
      PORel_PONum
      PORel_POLine
      POHeader_OrderDate
    }
  }
`;

export const REWORK_LOOKUP_QUERY = gql`
  query ReworkCodes($company: String!) {
    reworkCodes(company: $company) {
      value
      label
    }
  }
`;

export const DAYSWORKED_LOOKUP_QUERY = gql`
  query DaysWorked($company: String!, $empId: String!) {
    daysworked(company: $company, empId: $empId) {
      value
      label
    }
  }
`;

export const NCRCOMPANYLIST_LOOKUP_QUERY = gql`
  query NCRCompanyList {
    ncrCompanyList {
      CompanyID
      Name
      AllowNCR
    }
  }
`;

export const NCRPROJECTLIST_LOOKUP_QUERY = gql`
  query NCRProjectList($company: String!) {
    ncrProjectList(company: $company) {
      ProjectID
      ProjectName
    }
  }
`;

export const NCRJOBLIST_LOOKUP_QUERY = gql`
  query NCRJobList($company: String!, $projectID: String!) {
    ncrJobList(company: $company, projectID: $projectID) {
      JobNum
      JobDescription
    }
  }
`;

export const NCRSUPPLIERS_LOOKUP_QUERY = gql`
  query NCRSuppliersList($company: String!) {
    ncrSupplierList(company: $company) {
      VendorNum
      VendorName
    }
  }
`;

export const NCRCHAMPION_LOOKUP_QUERY = gql`
  query NCRChampion($company: String!) {
    ncrChampion(company: $company) {
      value
      label
    }
  }
`;

export const NCRINITIATOR_LOOKUP_QUERY = gql`
  query NCRInitiators($company: String!) {
    ncrInitiators(company: $company) {
      value
      label
    }
  }
`;

export const NCRSOURCES_LOOKUP_QUERY = gql`
  query NCRSources(
    $company: String!
    $whereClauseUDCodeType: String!
    $pageSize: String!
    $absolutePage: String!
  ) {
    ncrSources(
      company: $company
      whereClauseUDCodeType: $whereClauseUDCodeType
      pageSize: $pageSize
      absolutePage: $absolutePage
    ) {
      value
      label
    }
  }
`;

export const NCRREASONS_LOOKUP_QUERY = gql`
  query NCRReasons(
    $company: String!
    $whereClauseReason: String!
    $pageSize: String!
    $absolutePage: String!
  ) {
    ncrReasons(
      company: $company
      whereClauseReason: $whereClauseReason
      pageSize: $pageSize
      absolutePage: $absolutePage
    ) {
      value
      label
    }
  }
`;

export const NCRLOCATIONS_LOOKUP_QUERY = gql`
  query NCRLocations(
    $company: String!
    $whereClauseUDCodeType: String!
    $pageSize: String!
    $absolutePage: String!
  ) {
    ncrLocations(
      company: $company
      whereClauseUDCodeType: $whereClauseUDCodeType
      pageSize: $pageSize
      absolutePage: $absolutePage
    ) {
      value
      label
    }
  }
`;

export const NCRAREAS_LOOKUP_QUERY = gql`
  query NCRAreas(
    $company: String!
    $whereClauseUDCodeType: String!
    $pageSize: String!
    $absolutePage: String!
  ) {
    ncrAreas(
      company: $company
      whereClauseUDCodeType: $whereClauseUDCodeType
      pageSize: $pageSize
      absolutePage: $absolutePage
    ) {
      value
      label
    }
  }
`;

export const HOLIDAY_LOOKUP_QUERY = gql`
  query HolidayLookup($company: String!) {
    holidayLookup(company: $company) {
      PRHoldy_Company
      PRHoldy_Holiday
      PRHoldy_Description
      PRHoldy_PayHours
    }
  }
`;

export const RECALL_TIMESHEET_APPROVAL_MUTATION = gql`
mutation RecallTimesheeApprovalt($ts: TimesheetInput!, $dates: [Date]!) {
  recallTimesheetApproval(ts: $ts, dates: $dates) {
    ts {
      ${timesheetFields}
    }
    message
  }
}
`;

export const RECALL_TIMESHEET_SUBMISSION_MUTATION = gql`
mutation RecallTimesheetSubmission($ts: TimesheetInput!, $dates: [Date]!) {
  recallTimesheetSubmission(ts: $ts, dates: $dates) {
    ts {
      ${timesheetFields}
    }
    message
  }
}
`;

export const SUBMIT_TIMESHEET_MUTATION = gql`
  mutation SubmitTimesheet($ts: TimesheetInput!, $dates: [Date]!) {
    submitTimesheet(ts: $ts, dates: $dates) {
      ${timesheetFields}
    }
  }
`;

export const APPROVE_TIMESHEET_MUTATION = gql`
  mutation ApproveTimesheet($ts: TimesheetInput!, $dates: [Date]!, $approverId: String!) {
    approveTimesheet(ts: $ts, dates: $dates, approverId: $approverId) {
      ${timesheetFields}
    }
  }
`;

export const DELETE_ROW_MUTATION = gql`
  mutation DeleteRow($ts: TimesheetInput!, $row: TimesheetRowInput!) {
    deleteTimesheetRow(ts: $ts, row: $row)
  }
`;

export const UPDATE_ROW_MUTATION = gql`
  mutation UpdateRow(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $input: TimesheetRowInput!
  ) {
    updateTimesheetRow(ts: $ts, row: $row, input: $input)
  }
`;

export const DELETE_ENTRY_MUTATION = gql`
  mutation DeleteEntry($id: ID!, $company: String!) {
    deleteTimesheetEntry(id: $id, company: $company)
  }
`;

export const SAVE_TIME_MUTATION = gql`
  mutation SaveTime(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $entry: TimesheetEntryInput!
    $submit: Boolean
  ) {
    saveTime(ts: $ts, row: $row, entry: $entry, submit: $submit) {
      id
      Date
      TimeAllocation
      InLieuType
      OvernightCompensation
      PayType
      Submitted
      ClockInTime
      ClockOutTime
      ClockInDate
      ClockOutDate
      LaborHed {
        laborHedSeq
        payrollDate
        clockInTime
        clockOutTime
        payType
        payHours
      }
    }
  }
`;

export const COPY_PREVIOUS_WEEK_MUTATION = gql`
mutation CopyPreviousWeek($ts: TimesheetInput!, $includeTime: Boolean) {
  copyPreviousWeek(ts: $ts, includeTime: $includeTime) {
    ts {
      ${timesheetFields}
    }
    message
  }
}
`;

export const SAVE_NARRATIVE_MUTATION = gql`
  mutation SaveNarrative(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $detail: TimesheetEntryDetailInput
    $narrative: String!
  ) {
    saveTimeNarrative(
      ts: $ts
      row: $row
      detail: $detail
      narrative: $narrative
    ) {
      id
      Narrative
    }
  }
`;

export const SAVE_TIME_INLIEU = gql`
  mutation SaveTimeInLieu(
    $ts: TimesheetInput!
    $date: Date!
    $description: String!
  ) {
    saveTimeInLieu(ts: $ts, date: $date, description: $description) {
      id
      Date
      Descrition
    }
  }
`;

export const SAVE_ON_CALL_MUTATION = gql`
  mutation SaveOnCall(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $entry: TimesheetEntryInput!
  ) {
    saveOnCall(ts: $ts, row: $row, entry: $entry) {
      id
      Date
      OnCall
    }
  }
`;

export const UPDATE_PERCENT_COMPLETE = gql`
  mutation UpdatePercentComplete(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $percentComplete: Float!
  ) {
    updatePercentComplete(
      ts: $ts
      row: $row
      percentComplete: $percentComplete
    ) {
      RunQty
      CompletedQty
      OpComplete
    }
  }
`;

export const UPDATE_OP_OUTLOOK = gql`
  mutation UpdateOpOutlook(
    $ts: TimesheetInput!
    $row: TimesheetRowInput!
    $opOutlook: Float!
  ) {
    updateOpOutlook(ts: $ts, row: $row, opOutlook: $opOutlook) {
      Outlook
    }
  }
`;

export const CREATE_NCR_MUTATION = gql`
  mutation CreateNCR(
    $company: String!
    $efxLibraryID: String!
    $efxFunctionID: String!
    $params: NCRInput!
  ) {
    createNCR(
      company: $company
      efxLibraryID: $efxLibraryID
      efxFunctionID: $efxFunctionID
      params: $params
    ) {
      output
      HDCaseNum
    }
  }
`;

export const CREATE_REQUISITION_MUTATION = gql`
  mutation CreateRequisition(
    $company: String!
    $efxLibraryID: String!
    $efxFunctionID: String!
    $params: RequisitionInput!
  ) {
    createRequisition(
      company: $company
      efxLibraryID: $efxLibraryID
      efxFunctionID: $efxFunctionID
      params: $params
    ) {
      output
    }
  }
`;

export const CREATE_REQUISITION_ATTACHMENT_MUTATION = gql`
  mutation CreateRequisitionAttachment(
    $company: String!
    $efxLibraryID: String!
    $efxFunctionID: String!
    $params: RequisitionAttachmentInput!
  ) {
    createRequisitionAttachment(
      company: $company
      efxLibraryID: $efxLibraryID
      efxFunctionID: $efxFunctionID
      params: $params
    ) {
      output
    }
  }
`;

export const UPDATE_LABOR_NOTES = gql`
  mutation UpdateLaborNotes(
    $laborHedSeq: Int!
    $laborDtlSeq: Int!
    $Narrative: String!
  ) {
    updateLaborNotes(
      input: {
        laborHedSeq: $laborHedSeq
        laborDtlSeq: $laborDtlSeq
        Narrative: $Narrative
      }
    ) {
      output
    }
  }
`;
