import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import rowShape from './shapes/rowShape';
import roundTime from '../api/roundTime';
import PercentCompleteSelectionOverlay from './PercentCompleteSelectionOverlay';
import OpOutlookSelectionOverlay from './OpOutlookSelectionOverlay';
import NoteIndicatorOverlay from './NoteIndicatorOverlay';
import Project from './Project';
import TimesheetDayTitles from './TimesheetDayTitles';
import TimesheetEntries from './TimesheetEntries';
import getNoteLockedReason from '../api/getNoteLockedReason';
import getPercentCompleteLockedReason from '../api/getPercentCompleteLockedReason';
import getOpOutlookLockedReason from '../api/getOpOutlookLockedReason';
import getPercentCompleteTooltipReason from '../api/getPercentCompleteTooltipReason';
import checkCompanyTable from '../hoc/companyConfig'; // Import the companyConfig logic

const TimesheetLine = props => {
  const {
    isActive,
    isEdit,
    isSavePending,
    isTimesheetReadOnly,
    mode,
    row,
    rowIndex,
    timesheet,
    updatePercentComplete,
    updateOpOutlook,
    updateNarrative,
    setActiveRow,
    labels,
    companyConfig
  } = props;

  const showPercentComplete = companyConfig?.AllowTSPercent;
  const showOutlook = companyConfig?.AllowTSOutlook;

  const hasProjectSelection = !!(row.Operation || row.IndirectCode);
  const isReadOnly = row.closed || isTimesheetReadOnly;

  const handleFocus = useCallback(() => {
    if (!isActive && !isReadOnly && (row.IndirectCode || row.Operation)) {
      setActiveRow(rowIndex);
    }
  }, [isActive, isReadOnly, row, rowIndex, setActiveRow]);

  if (isTimesheetReadOnly && !hasProjectSelection) {
    return null;
  }
  if (row.IsSystemEntry) return null;

  const noteLockedReason = getNoteLockedReason(row, mode);
  const percentCompleteLockedReason = getPercentCompleteLockedReason(row, mode);
  const percentCompleteTooltipReason = getPercentCompleteTooltipReason(row);
  const opOutlookLockedReason = getOpOutlookLockedReason(row, mode);

  const opOutlookTooltip =
    opOutlookLockedReason &&
    (props.labels.opOutlookLockedReason[opOutlookLockedReason] ||
      opOutlookLockedReason);

  const percentCompleteTooltip =
    (percentCompleteLockedReason &&
      (props.labels.percentCompleteLockedReason[percentCompleteLockedReason] ||
        percentCompleteLockedReason)) ||
    (percentCompleteTooltipReason &&
      (props.labels.percentCompleteTooltipReason[
        percentCompleteTooltipReason
      ] ||
        percentCompleteTooltipReason));

  const tooltip =
    noteLockedReason &&
    (props.labels.noteLockedReason[noteLockedReason] || noteLockedReason);

  const cls = classNames('timesheet-line row', {
    'timesheet-line--overhead': !!row.IndirectCode,
    'timesheet-line--new': !row.IndirectCode && !row.Operation,
    'timesheet-line--save-pending': isSavePending,
    'timesheet-line--active': isActive,
    'timesheet-line--edit': isEdit,
    'timesheet-line--closed': row.closed
  });

  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;

  const timesheetDaysClass = classNames('timesheet-days', {
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
    'col-md-6': showBoth
  });

  const timesheetProjectClass = classNames('timesheet-project', {
    'col-md-3': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-4': showNeither
  });

  let isRowEditable =
    !isTimesheetReadOnly &&
    !row.entries.some(
      x =>
        x.TimeAllocation > 0 &&
        (x.Approved || x.Posted || (mode === 'timesheet' && x.Submitted))
    );

  const allEntries = timesheet.rows.reduce((acc, currRow) => {
    return acc.concat(currRow.entries);
  }, []);

  return (
    <div className={cls} onClick={handleFocus} onFocus={handleFocus}>
      <div className={timesheetProjectClass}>
        <Project
          {...props}
          company={timesheet.Company}
          hasProjectSelection={hasProjectSelection}
          isEdit={isEdit}
          isRowEditable={isRowEditable}
          row={row}
          rowIndex={rowIndex}
          onFocus={handleFocus}
        />
      </div>
      <div className={timesheetDaysClass}>
        <TimesheetDayTitles entries={row.entries} totals={timesheet.totals} />
        <TimesheetEntries
          {...props}
          isReadOnly={!hasProjectSelection || isTimesheetReadOnly}
        />
      </div>

      {showPercentComplete && (
        <div className="percent-complete-row col-md-1 col-xs-12">
          <PercentCompleteSelectionOverlay
            row={row}
            disabled={
              !hasProjectSelection ||
              isTimesheetReadOnly ||
              percentCompleteLockedReason
            }
            onChange={updatePercentComplete}
            rowIndex={rowIndex}
            tooltip={percentCompleteTooltip}
          />
        </div>
      )}

      {showOutlook && (
        <div className="op-outlook-row col-md-1 col-xs-12">
          <OpOutlookSelectionOverlay
            row={row}
            disabled={
              !hasProjectSelection ||
              isTimesheetReadOnly ||
              opOutlookLockedReason
            }
            rowIndex={rowIndex}
            onChange={updateOpOutlook}
            tooltip={opOutlookTooltip}
          />
        </div>
      )}

      <div className="line-controls col-md-1 col-xs-12">
        <span className="row-total">Total</span>
        <span className="row-total">{roundTime(row.total)}</span>
        <NoteIndicatorOverlay
          tooltip={tooltip}
          isSavePending={isSavePending}
          isTimesheetReadOnly={isReadOnly}
          row={row}
          updateNarrative={updateNarrative}
          timesheet={timesheet}
          entries={row?.entries}
          labels={labels}
          {...props}
        />
      </div>
    </div>
  );
};

TimesheetLine.propTypes = {
  isActive: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSavePending: PropTypes.bool.isRequired,
  isTimesheetReadOnly: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  row: rowShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  timesheet: PropTypes.object.isRequired,
  timesheetSource: PropTypes.object,
  updatePercentComplete: PropTypes.func.isRequired,
  updateOpOutlook: PropTypes.func,
  updateNarrative: PropTypes.func,
  setActiveRow: PropTypes.func.isRequired,
  labels: PropTypes.object
};

export default TimesheetLine;
