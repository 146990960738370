// NoteIndicator.js

import React, {useState} from 'react';
import {withState} from 'recompose';
import AddNoteDialog from './AddNoteDialog';
import ClearButton from '../../shared/components/ClearButton';
import Glyphicon from '../../shared/components/Glyphicon';
import classNames from 'classnames';
import {Menu, MenuItem, Dialog} from '@material-ui/core';
import DailyNotesModal from '../../approval/components/DailyNotesModal';

const NoteIndicator = ({
  isReadOnly,
  isSavePending,
  note,
  onDialogClose,
  updateNarrative,
  dialogOpen,
  setDialogOpen,
  timesheet,
  entries,
  labels,
  isTimesheetReadOnly
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDailyNotes, setShowDailyNotes] = useState(false);

  if (isReadOnly && !note) {
    return null;
  }

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewEditNotes = () => {
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleDailyNotes = () => {
    setShowDailyNotes(true);
    handleMenuClose();
  };

  const onClosed = () => {
    setDialogOpen(false);
    setShowDailyNotes(false);
    if (onDialogClose) onDialogClose();
  };

  const cls = 'timesheet-line--note-indicator';

  return (
    <div className={classNames(cls)}>
      <ClearButton
        onClick={() => setShowDailyNotes(true)}
        disabled={isSavePending}>
        <Glyphicon
          label="View / Edit Notes"
          glyph={note ? 'sticky-note' : 'sticky-note-o'}
        />
      </ClearButton>
      {showDailyNotes && (
        <Dialog
          open={showDailyNotes}
          onClose={onClosed}
          fullWidth
          maxWidth="xl">
          <DailyNotesModal
            timesheet={timesheet}
            entries={entries}
            isReadOnly={isReadOnly}
            updateNarrative={params => {
              console.log('updateNarrative called with:', params);
              updateNarrative(params);
            }}
            onClose={onClosed}
            labels={labels}
          />
        </Dialog>
      )}
    </div>
  );
};

const container = withState('dialogOpen', 'setDialogOpen', false);

export default container(NoteIndicator);
