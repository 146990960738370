// DailyNotesModal.js

import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import dateUtils from '../../shared/utils/dateUtils';
import getDayOfWeek from '../../utils/getDayOfWeek';
import Spinner from '../../shared/components/Spinner';
import '../../scss/timesheet/_noteDialog.scss';
import {UPDATE_LABOR_NOTES} from '../../timesheet/queries';

const DailyNotesModal = ({
  timesheet,
  entries,
  isReadOnly,
  labels,
  onClose,
  updateNarrative
}) => {
  const [localEntriesLookup, setLocalEntriesLookup] = useState(
    createLocalEntryLookup(
      entries?.map(e => ({
        ...e,
        Narrative: e.Narrative || ''
      }))
    ) || []
  ); // This was a quick bug fix - but is obviously not DRY - component needs to be reworked to fix this
  const [localEntries, setLocalEntries] = useState(() => {
    return (
      entries?.map(e => ({
        ...e,
        Narrative: e.Narrative || ''
      })) || []
    );
  });

  const [successState, setSuccessState] = useState({});
  const [errorState, setErrorState] = useState({});

  const handleChangeNarrative = (entryId, value) => {
    const entryToChange = localEntriesLookup[entryId];
    entryToChange.Detail[0] = {
      ...entryToChange.Detail[0],
      Narrative: value,
      isModified: true
    };
    // another ripple from the emergency bug fix - component needs to be rewritten to factor out this array
    const updated = localEntries.map(entry => {
      if (entry.id === entryId) return entryToChange;
      return entry;
    });

    setLocalEntries(updated);
    setLocalEntriesLookup(createLocalEntryLookup(updated));
  };

  const handleUpdateRow = async entry => {
    if (!entry.id) {
      console.error('No id found on entry. Cannot update narrative.', entry);
      return;
    }

    if (entry.id.startsWith('TMP_')) {
      console.log('Skipping TMP_ entry:', entry.id);
      return;
    }

    const [laborHedSeqStr, laborDtlSeqStr] = entry.id.split('/');
    const laborHedSeq = Number(laborHedSeqStr);
    const laborDtlSeq = Number(laborDtlSeqStr);

    if (isNaN(laborHedSeq) || isNaN(laborDtlSeq)) {
      console.error('Invalid id format:', entry.id);
      setErrorState(prevState => ({...prevState, [entry.id]: true}));
      setSuccessState(prevState => ({...prevState, [entry.id]: false}));
      setTimeout(() => {
        setErrorState(prevState => ({...prevState, [entry.id]: false}));
      }, 2000);
      return;
    }

    const narrative =
      localEntries.find(e => e.id === entry.id)?.Detail[0]?.Narrative || '';

    try {
      setSuccessState(prevState => ({...prevState, [entry.id]: 'loading'}));

      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          query: UPDATE_LABOR_NOTES,
          variables: {
            laborHedSeq,
            laborDtlSeq,
            Narrative: narrative
          }
        })
      });

      if (!response.ok) {
        console.error('Failed to update narrative:', response);
        throw new Error('Failed to update narrative');
      }

      const jsonResponse = await response.json();
      if (jsonResponse.errors) {
        console.error('GraphQL error:', jsonResponse.errors);
        throw new Error('GraphQL errors occurred');
      }

      console.log('Narrative update response:', jsonResponse);
      const updated = localEntries.map(e =>
        e.id === entry.id ? {...e, isModified: false} : e
      );
      setLocalEntries(updated);
      setLocalEntriesLookup(createLocalEntryLookup(updated));

      setSuccessState(prevState => ({...prevState, [entry.id]: true}));
      setTimeout(() => {
        setSuccessState(prevState => ({...prevState, [entry.id]: false}));
      }, 2000);
    } catch (error) {
      console.error('Error updating narrative:', error);
      setErrorState(prevState => ({...prevState, [entry.id]: true}));
      setSuccessState(prevState => ({...prevState, [entry.id]: false}));
      setTimeout(() => {
        setErrorState(prevState => ({...prevState, [entry.id]: false}));
      }, 2000);
    }
  };

  const rows = getRows({entries: localEntries, timesheet});

  return (
    <div className="col-md-12 daily-notes-modal">
      <div className="modal-header-container">
        <h2>Daily Notes</h2>
        <button onClick={onClose} className="close-button">
          &times;
        </button>
      </div>
      <div className="modal-body-container">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Hours</th>
                <th>Project</th>
                <th>Notes</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                const currentEntry = localEntriesLookup[row.id];
                if (!currentEntry) return null;

                const isTMP = currentEntry.id.startsWith('TMP_');
                const successActive = successState[currentEntry.id];
                const errorActive = errorState[currentEntry.id];

                if (isTMP) return null;

                return (
                  <tr key={row.id}>
                    <td data-label="Date">{row.date}</td>
                    <td data-label="Hours">{row.hours}</td>
                    <td data-label="Project">{row.project}</td>
                    <td data-label="Notes">
                      <textarea
                        className={
                          errorActive
                            ? 'notes-textarea shake'
                            : 'notes-textarea'
                        }
                        value={currentEntry.Detail[0]?.Narrative || ''}
                        onChange={e =>
                          handleChangeNarrative(row.id, e.target.value)
                        }
                        disabled={isReadOnly || isTMP}
                        style={{
                          outline:
                            successActive === true
                              ? '2px solid rgb(10, 223, 106)'
                              : errorActive
                              ? '2px solid #cc0000'
                              : 'none'
                        }}
                        placeholder={
                          isTMP
                            ? 'Temporary entry - cannot edit'
                            : 'Enter notes here...'
                        }
                      />
                    </td>
                    <td data-label="Action" className="action-td">
                      {successActive === true ? (
                        <div className="success-animation">
                          <svg
                            className="checkmark"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52">
                            <circle
                              className="checkmark__circle"
                              cx="26"
                              cy="26"
                              r="25"
                              fill="none"
                            />
                            <path
                              className="checkmark__check"
                              fill="none"
                              d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            />
                          </svg>
                        </div>
                      ) : (
                        <button
                          onClick={() => handleUpdateRow(row)}
                          disabled={
                            isReadOnly || isTMP || successActive === 'loading'
                          }
                          className="submit-button">
                          {successActive === 'loading' ? <Spinner /> : 'Submit'}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer-container"></div>
    </div>
  );
};

DailyNotesModal.propTypes = {
  timesheet: PropTypes.object.isRequired,
  entries: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  updateNarrative: PropTypes.func.isRequired
};

export default DailyNotesModal;

function getRows({entries, timesheet}) {
  const rows = [];

  entries.forEach((entry, entryIndex) => {
    if (entry.id && entry.id.startsWith('TMP_')) {
      return;
    }

    const date = new Date(entry.Date);
    const dayOfWeek = getDayOfWeek(date);
    const dateFormatted = `${dayOfWeek.slice(0, 3)} ${date.getUTCDate()}`;

    let projectLabel = '';
    const rowIndex = findRowIndex(timesheet.rows, entry);
    if (rowIndex !== -1) {
      const row = timesheet.rows[rowIndex];
      const jobLabel = row.Job ? row.Job.label : '';
      const operationLabel = row.Operation ? row.Operation.label : '';
      const indirectLabel = row.IndirectCode ? row.IndirectCode.label : '';
      const labels = [
        row.Project ? row.Project.label : '',
        jobLabel,
        operationLabel,
        indirectLabel
      ].filter(Boolean);
      projectLabel = labels.join(' - ');
    }

    rows.push({
      date: dateFormatted,
      hours: Number(entry.TimeAllocation || 0).toFixed(2),
      project: projectLabel,
      notes: entry.Narrative,
      entryIndex,
      id: entry.id
    });
  });

  return rows;
}

function findRowIndex(rows, entry) {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].entries.some(e => e.id === entry.id)) {
      return i;
    }
  }
  return -1;
}

function createLocalEntryLookup(localEntries = []) {
  return localEntries.reduce((cache, entry) => {
    if (entry.id) cache[entry.id] = entry;
    return cache;
  }, {});
}
